import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import Helmet from "react-helmet";
import styles from "./about-partner.module.css";

import "../layouts/tachyons/css/tachyons.css";
import "../layouts/custom.css";

const aboutPartner = props => (
  <div className={styles.partnerbox}>
    <div className="v-mid w-100 pa3 flex flex-wrap">
      <div className="center w-100 v-mid">
        <img className="h3" src={props.logo} />
      </div>
      <h3>{props.name}</h3>
      <hr />
      <p>{props.description}</p>
      <hr />

      <a target="_blank" rel="noopener" className="dib link v-top fill-gray" href={props.weblink}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="dib v-mid h-100 ph3 fbfix"
        >
          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 16.057v-3.057h2.994c-.059 1.143-.212 2.24-.456 3.279-.823-.12-1.674-.188-2.538-.222zm1.957 2.162c-.499 1.33-1.159 2.497-1.957 3.456v-3.62c.666.028 1.319.081 1.957.164zm-1.957-7.219v-3.015c.868-.034 1.721-.103 2.548-.224.238 1.027.389 2.111.446 3.239h-2.994zm0-5.014v-3.661c.806.969 1.471 2.15 1.971 3.496-.642.084-1.3.137-1.971.165zm2.703-3.267c1.237.496 2.354 1.228 3.29 2.146-.642.234-1.311.442-2.019.607-.344-.992-.775-1.91-1.271-2.753zm-7.241 13.56c-.244-1.039-.398-2.136-.456-3.279h2.994v3.057c-.865.034-1.714.102-2.538.222zm2.538 1.776v3.62c-.798-.959-1.458-2.126-1.957-3.456.638-.083 1.291-.136 1.957-.164zm-2.994-7.055c.057-1.128.207-2.212.446-3.239.827.121 1.68.19 2.548.224v3.015h-2.994zm1.024-5.179c.5-1.346 1.165-2.527 1.97-3.496v3.661c-.671-.028-1.329-.081-1.97-.165zm-2.005-.35c-.708-.165-1.377-.373-2.018-.607.937-.918 2.053-1.65 3.29-2.146-.496.844-.927 1.762-1.272 2.753zm-.549 1.918c-.264 1.151-.434 2.36-.492 3.611h-3.933c.165-1.658.739-3.197 1.617-4.518.88.361 1.816.67 2.808.907zm.009 9.262c-.988.236-1.92.542-2.797.9-.89-1.328-1.471-2.879-1.637-4.551h3.934c.058 1.265.231 2.488.5 3.651zm.553 1.917c.342.976.768 1.881 1.257 2.712-1.223-.49-2.326-1.211-3.256-2.115.636-.229 1.299-.435 1.999-.597zm9.924 0c.7.163 1.362.367 1.999.597-.931.903-2.034 1.625-3.257 2.116.489-.832.915-1.737 1.258-2.713zm.553-1.917c.27-1.163.442-2.386.501-3.651h3.934c-.167 1.672-.748 3.223-1.638 4.551-.877-.358-1.81-.664-2.797-.9zm.501-5.651c-.058-1.251-.229-2.46-.492-3.611.992-.237 1.929-.546 2.809-.907.877 1.321 1.451 2.86 1.616 4.518h-3.933z" />
        </svg>
      </a>

      <a
        target="_blank"
        rel="noopener"
        className="dib pr20 link v-top fill-gray"
        href={props.fblink}
      >
        <svg
          width="10"
          height="21"
          viewBox="0 0 10 21"
          xmlns="http://www.w3.org/2000/svg"
          className="dib v-mid h-100 ph3 fbfix"
        >
          <title>Facebook Icon</title>
          <path
            d="M6.512 6.88V5.073c0-.272.012-.482.037-.631A1.24 1.24 0 0 1 6.722 4a.711.711 0 0 1 .433-.3c.2-.054.465-.082.797-.082h1.81V0H6.869C5.195 0 3.993.397 3.263 1.191c-.731.795-1.096 1.964-1.096 3.51v2.18H0V10.5h2.167V21h4.345V10.5h2.892l.383-3.62H6.512z"
            fillRule="evenodd"
          ></path>
        </svg>
      </a>

      <a
        target="_blank"
        rel="noopener"
        className="dib pr20 link v-top fill-gray"
        href={props.iglink}
      >
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          xmlns="http://www.w3.org/2000/svg"
          className="ph3 igfix"
        >
          <title>Instagram Icon</title>
          <path d="M15.664.112H6.457C3.33.112.787 2.61.787 5.68v9.042c0 3.071 2.543 5.57 5.67 5.57h9.207c3.127 0 5.67-2.5 5.67-5.57V5.681c0-3.07-2.543-5.57-5.67-5.57zm3.847 14.611c0 2.084-1.726 3.779-3.847 3.779H6.457c-2.121 0-3.847-1.695-3.847-3.779V5.681c0-2.084 1.726-3.779 3.847-3.779h9.207c2.121 0 3.847 1.695 3.847 3.779v9.042z"></path>
          <path d="M10.693 5.32c-2.92 0-5.294 2.332-5.294 5.2 0 2.867 2.375 5.2 5.294 5.2 2.92 0 5.295-2.333 5.295-5.2 0-2.868-2.375-5.2-5.295-5.2zm0 8.608c-1.914 0-3.471-1.529-3.471-3.409s1.557-3.41 3.471-3.41c1.915 0 3.472 1.53 3.472 3.41 0 1.88-1.558 3.41-3.472 3.41zM15.96 3.475c-.352 0-.697.14-.945.384-.25.244-.393.583-.393.93 0 .344.144.683.393.928a1.357 1.357 0 0 0 1.89 0c.249-.245.391-.584.391-.929 0-.346-.142-.685-.391-.929a1.354 1.354 0 0 0-.946-.384z"></path>
        </svg>
      </a>

      <a
        target="_blank"
        rel="noopener"
        class="dib pr20 link v-top fill-gray"
        href={props.twitterlink}
      >
        <svg
          width="20"
          height="17"
          viewBox="0 0 20 17"
          xmlns="http://www.w3.org/2000/svg"
          className="dib v-mid h-100 ph3"
        >
          <title>Twitter Icon</title>
          <path
            d="M18.248 2.351A4.2 4.2 0 0 0 19.38.581c.063-.194-.151-.361-.33-.258a8.01 8.01 0 0 1-2.101.854.563.563 0 0 1-.5-.122A4.343 4.343 0 0 0 13.605 0c-.454 0-.912.07-1.362.207a4.122 4.122 0 0 0-2.807 2.948 4.43 4.43 0 0 0-.102 1.555.15.15 0 0 1-.038.118.161.161 0 0 1-.119.053C6.11 4.601 3.359 3.158 1.414.814a.227.227 0 0 0-.368.028 4.164 4.164 0 0 0-.582 2.123 4.18 4.18 0 0 0 1.281 3.01 3.891 3.891 0 0 1-.98-.372.225.225 0 0 0-.334.19c-.022 1.701.992 3.215 2.51 3.896l-.091.001c-.24 0-.485-.023-.724-.068a.222.222 0 0 0-.257.284c.493 1.512 1.818 2.627 3.404 2.88A8.035 8.035 0 0 1 .829 14.11H.332a.328.328 0 0 0-.322.244.332.332 0 0 0 .163.37 11.96 11.96 0 0 0 5.93 1.57c1.822 0 3.526-.356 5.066-1.056a11.173 11.173 0 0 0 3.707-2.73 11.922 11.922 0 0 0 2.27-3.734c.505-1.325.772-2.739.772-4.088v-.064c0-.217.1-.42.274-.56A8.406 8.406 0 0 0 19.9 2.22c.125-.184-.072-.414-.278-.324a7.98 7.98 0 0 1-1.375.455z"
            fillRule="evenodd"
          ></path>
        </svg>
      </a>
    </div>
  </div>
);

export default aboutPartner;
